<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.projectTeam.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.projectTeam.list.title"></app-i18n>
      </h1>
     
      <app-project-team-list-filter></app-project-team-list-filter>
      <app-project-team-list-table></app-project-team-list-table>
    </div>
  </div>
</template>

<script>
import ProjectTeamListFilter from '@/modules/project-team/components/project-team-list-filter.vue';
import ProjectTeamListTable from '@/modules/project-team/components/project-team-list-table.vue';
import ProjectTeamListToolbar from '@/modules/project-team/components/project-team-list-toolbar.vue';

export default {
  name: 'app-project-team-list-page',

  components: {
    [ProjectTeamListFilter.name]: ProjectTeamListFilter,
    [ProjectTeamListTable.name]: ProjectTeamListTable,
    [ProjectTeamListToolbar.name]: ProjectTeamListToolbar,
  },
};
</script>

<style>
</style>
