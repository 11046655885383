<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column
        :label="fields.project.label"
        :prop="fields.project.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.project.label"
            :readPermission="fields.project.readPermission"
            :navigatePermission="fields.project.navigatePermission"
            :url="fields.project.viewUrl"
            :value="presenter(scope.row, 'project')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.projectManager.label"
        :prop="fields.projectManager.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-many
            :label="fields.projectManager.label"
            :readPermission="fields.projectManager.readPermission"
            :navigatePermission="
              fields.projectManager.navigatePermission
            "
            :url="fields.projectManager.viewUrl"
            :value="presenter(scope.row, 'projectManager')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.teamMember.label"
        :prop="fields.teamMember.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-many
            :label="fields.teamMember.label"
            :readPermission="fields.teamMember.readPermission"
            :navigatePermission="fields.teamMember.navigatePermission"
            :url="fields.teamMember.viewUrl"
            :value="presenter(scope.row, 'teamMember')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link
              :to="`/project-team/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { ProjectTeamModel } from '@/modules/project-team/project-team-model';
import { mapGetters, mapActions } from 'vuex';
import { ProjectTeamPermissions } from '@/modules/project-team/project-team-permissions';
import { i18n } from '@/i18n';

const { fields } = ProjectTeamModel;

export default {
  name: 'app-project-team-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'projectTeam/list/rows',
      count: 'projectTeam/list/count',
      loading: 'projectTeam/list/loading',
      pagination: 'projectTeam/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'projectTeam/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new ProjectTeamPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ProjectTeamPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'projectTeam/list/doChangeSort',
      doChangePaginationCurrentPage:
        'projectTeam/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'projectTeam/list/doChangePaginationPageSize',
      doMountTable: 'projectTeam/list/doMountTable',
      doDestroy: 'projectTeam/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      const value = ProjectTeamModel.presenter(row, fieldName);
      return value;
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style>
</style>
