<template>
  <div class="app-page-toolbar">
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { ProjectTeamPermissions } from '@/modules/project-team/project-team-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-project-team-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'projectTeam/list/hasRows',
      loading: 'projectTeam/list/loading',
      exportLoading: 'projectTeam/list/exportLoading',
      selectedRows: 'projectTeam/list/selectedRows',
      destroyLoading: 'projectTeam/destroy/loading',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToEdit() {
      return new ProjectTeamPermissions(this.currentUser).edit;
    },

    exportButtonDisabled() {
      return !this.hasRows || this.loading || this.exportLoading;
    },

    exportButtonTooltip() {
      return !this.hasRows ? i18n('common.noDataToExport') : null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'projectTeam/list/doExport',
    }),
  },
};
</script>

<style>
</style>
